import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { getExEmployee, getExEmployeeDetails } from "../../../Services/authHeader";
import ExEmployee from './addExEmployeeForm'

const ExEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);


  const EmployeeDetails = async (id) => {
    try {
      const data = await getExEmployeeDetails(id)
      setSelectedEmployee(data.data.data);
    } catch (error) {
      console.log("Getting Error in ExEmployeeDetails", error)
    }
  }

  async function getEmployees() {
    try {
      const data = await getExEmployee();
      // console.log("Data:", data);
      setEmployees(data);
    } catch (error) {
      console.log("Error:", error);
    }
  }
  document.body.classList.remove("ex-employee");
  useEffect(() => {
    getEmployees();
  }, []);

  const columns = [
    { title: "Name", dataIndex: "first_name", key: "first_name" },
    { title: "Creation Date", dataIndex: "creation", key: "creation" },
    { title: "Submitted By", dataIndex: "submitted_by", key: "submitted_by" },
    { title: "Joined On", dataIndex: "joined_on", key: "joined_on" },
    { title: "Relieved On", dataIndex: "released_on", key: "released_on" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    { title: "Designation", dataIndex: "designation", key: "designation" },
    { title: "Feedback", dataIndex: "type_of_review", key: "type_of_review" },
    {
      title: "Status",
      dataIndex: "workflow_state",
      key: "workflow_state",
      render: (workflow_state) => workflow_state === "Draft" ? "Submitted" : workflow_state,
    },
    {
      title: "Action",
      key: "view",
      render: (_, record) => (
        <button
          onClick={() => handleViewClick(record)}
          className="text-blue-600 hover:underline"
        >
          View
        </button>
      ),
    },
  ];


  const handleViewClick = async (record) => {
    await EmployeeDetails(record.name)
    // setSelectedEmployee(record);
    setShowForm(true);
  };


  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedEmployee(null);
  };

  const handleReset = () => {
    setSelectedEmployee(null);
  };

  const handleFormSubmit = async () => {
    await getEmployees();
    // handleCloseForm();
  };

  return (
    <div>
    {showForm ? (
      <ExEmployee
        employee={selectedEmployee}
        onClose={handleCloseForm}
        reset={handleReset}
        onFormSubmit={handleFormSubmit}
        EmployeeDetails={EmployeeDetails}
      />
    ) : (
      <Table
        dataSource={employees}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey="id"
      />
    )}
  </div>
  );
};

export default ExEmployees;
