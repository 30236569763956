import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ExEmployess from "./exEmployess";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  approveDeleteRequest,
  DeleteFileNonJoinerFileAPICall,
  deleteReviewRequest,
  getTypeOfReview,
  getUserCompany,
  submitExEmployeeReview,
  submitNonJoinersReview,
  updateNonJoiners,
  uploadFile,
} from "../../../Services/authHeader";
import { Checkbox, Form, notification, Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import { PageConfig } from "../../../Services/Context";
import ConfirmationModal from "../../../Widgets/confirmPopup";
import { CompanyWorkFlowConfig } from "../../../Services/companyContext";

const styles = {
  input: {
    opacity: "100%",
    position: "absolute",
  },
};

const AddNonJoiners = ({ employee, onClose, onFormSubmit, isSuperadmin, NonJoinerDetails }) => {
  const [file, setFile] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newloading, setnewLoading] = useState({
    fieldName: "",
    status: false,
  });
  const [fileNames, setFileNames] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
    file5: "",
  });
  const inputFileRef = useRef();
  const inputDocRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const imgRef = useRef();
  // const { reset } = props;
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(true);
  const [hideEditButton, setHideEditButton] = useState(true);
  const getReviewID = sessionStorage.getItem("reviewID");
  const [isReview, setReview] = useState(false);
  const [editReview, setEditReview] = useState(false);
  const [selectID, setSelectID] = useState(undefined);
  const [emailError, setEmailError] = useState("");
  const [typeOfReviews, setTypeOfReviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(!employee);

  const [adharcard, setadharcard] = useState("");
  const [voterid, setvoterid] = useState("");
  const [pancard, setpancard] = useState("");
  const [roleprofile, setroleprofile] = useState({});

  const [IdGenerateIndicator, setIdGenerateIndicator] = useState(false);
  const [IDSave, setIDSave] = useState(null);
  const { active, closeform, setcloseform, setTypeTab } = PageConfig();
  const [showModal, setShowModal] = useState(false);
  const [showModalForDocDelete, setShowModalForDocDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [reconfig, setreconfig] = useState(false)
  const { workflow_state } = CompanyWorkFlowConfig()
  // const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"));
  const user = JSON.parse(sessionStorage.getItem("usr"));

  const Url = process.env.REACT_APP_API_URL;


  useEffect(() => {
    if (NonJoinerDetails) {
      NonJoinerDetails(employee?.name)
    }
  }, [reconfig])

  useEffect(() => {
    setTypeTab("NonJoiner");
  }, []);

  useEffect(() => {
    if (closeform === "close") {
      setcloseform(null);
      onClose();
    }
  }, [active]);

  const fetchCompanyDetails = async (email) => {
    try {
      const res = await getUserCompany(email);
      if (res.status === 200) {
        setroleprofile(res.data.data);
      } else {
        console.log("Error fetching company name!");
      }
    } catch (error) {
      console.log("Error fetching company details:", error);
    }
  };

  useEffect(() => {
    if (user?.email) {
      fetchCompanyDetails(user?.email);
    }
  }, [user?.email]);

  const addExForm = Yup.object().shape({
    first_name: Yup.string().required("Please enter the first name"),
    last_name: Yup.string().required("Please enter the last name"),
    date_of_birth: Yup.string().required("Please enter DOB"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Please enter a valid phone number")
      .required("Please enter the phone number"),
    email: Yup.string().email("Invalid email"),
    enter_id_type: Yup.string().required("Please enter the ID Type"),
    review_details: Yup.string().required("Please add the review"),
    designation: Yup.string().required("Please enter the designation"),
    highest_education: Yup.string().required(
      "Please enter the highest education"
    ),
    schoolcollegeinstitute: Yup.string().required(
      "Please enter the school/college/institution"
    ),
    boarduniversity: Yup.string().required("Please enter the board/university"),
    city: Yup.string().required("Please enter the city"),
    state: Yup.string().required("Please enter the state"),
    year: Yup.string().max(4).required("Please enter the year"),
  });

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const data = await getTypeOfReview();
        setTypeOfReviews(data.message.values);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  const validateFormik = useFormik({
    initialValues: {
      first_name: employee?.first_name || "",
      aadhar_card: employee?.aadhar_card || "",
      pan_card: employee?.pan_card || "",
      voter_id: employee?.voter_id || "",
      last_name: employee?.last_name || "",

      date_of_birth: employee?.date_of_birth || "",
      other_id_proof_number: employee?.other_id_proof_number || "",
      enter_id_type: employee?.enter_id_type || "",
      phone: employee?.phone || "",
      email: employee?.email || "",
      designation: employee?.designation || "",
      review_details: employee?.review_details || "",
      // company
      company_name: employee?.company_name || "",
      location: employee?.location || "",
      joined_on: employee?.joined_on || "",
      released_on: employee?.released_on || "",
      previous_designation: employee?.previous_designation || "",
      // commpany
      highest_education: employee?.highest_education || "",
      schoolcollegeinstitute: employee?.schoolcollegeinstitute || "",
      boarduniversity: employee?.boarduniversity || "",
      city: employee?.city || "",
      state: employee?.state || "",
      year: employee?.year || undefined,
    },
    validationSchema: addExForm,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (values.submitAction === "next") {
        await handleSubmission(values);
      } else if (values.submitAction === "submit") {
        await NextAfterIDGenerate();
      }
      setSubmitting(false);
    },
  });

  const handleSubmission = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const res = await submitNonJoinersReview(values);
      await sessionStorage.setItem("ReviewID", JSON.stringify(res?.data?.name));
      setIDSave(res?.data?.name);
      setIdGenerateIndicator(true);
      toast.success("ID is created Please Upload Required Documents");
      return res;
    } catch (error) {
      
      if (error?.message === "Phone no. already exist.") {
        toast.error("Phone no. already exist.");
      } else {
        // console.log("error", error);
        toast.error("Failed to submit review. Please try again.");
      }

    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const NextAfterIDGenerate = () => {
    try {
      const employeeID = sessionStorage.getItem("ReviewID");

      if (IDSave || employeeID) {
        toast.success("Review submitted successfully!");
        setTimeout(() => {
          setIdGenerateIndicator((prev) => !prev);
          handleRemoveForm();
          sessionStorage.removeItem("ReviewID");
          onClose();
        }, 3000);
      }
    } catch (error) {
      console.log("error in NextAfterIDGenerate", error);
    }
  };

  // const handleSubmission = async (values) => {
  //   if (isSubmitting) return;

  //   setIsSubmitting(true);
  //   try {
  //     await submitNonJoinersReview(values);
  //     handleRemoveForm();
  //     toast.success("Review submitted successfully!");
  //     onClose();
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error("Failed to submit review. Please try again.");
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  // const onFileChange = (event, fileKey) => {
  //   const fileName = event.target.files[0]?.name || "";
  //   setFileNames((prevState) => ({
  //     ...prevState,
  //     [fileKey]: fileName,
  //   }));
  // };

  const onFileChange = (event, fileKey) => {
    // const fileName = event.target.files[0]?.name || "";

    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected or file type is invalid");
      return;
    }

    const allowedFileTypes = [
      "image/jpeg", // .jpg, .jpeg
      "image/png",   // .png
      "application/pdf", // .pdf
      "application/msword", // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    ];;
    const fileType = file.type;

    // Check if file is valid
    if (!allowedFileTypes.includes(fileType)) {
      toast.error("Invalid file type. Allowed types are: .png, .jpg, .jpeg, .pdf, .doc, .docx");
      return;
    }

    const reader = new FileReader();
    reader.onload = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      const fileData = btoa(binaryString);
      CustomUploadFile({ fileName: file.name, fileData, fieldName: fileKey });
    };
    reader.readAsBinaryString(file);

    if (fileKey === "aadhar_card") {
      setnewLoading({ fieldName: "aadhar_card", status: true });
      setadharcard(file.name);
    } else if (fileKey === "voter_id") {
      setnewLoading({ fieldName: "voter_id", status: true });
      setvoterid(file.name);
    } else if (fileKey === "pan_card") {
      setnewLoading({ fieldName: "pan_card", status: true });
      setpancard(file.name);
    }
  };

  const DeleteFileExEmployeeFile = async (fieldName, review_id) => {
    console.log("jsdbsbsd", fieldName)
    const body = {
      data: {
        [fieldName]: ""
      }
    };
    try {
      const deleteRes = await DeleteFileNonJoinerFileAPICall(review_id, body)

      if (deleteRes.status === 200) {
        // setadharcard("")
        toast.success("Delete Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setreconfig((prev) => !prev)

      }
    } catch (err) {
      console.log("Error got in DeleteFileFunction", err)
    }
  }


  const handleDeleteClick = (fileName) => {
    setFileToDelete(fileName);
    setShowModal(true); // Show confirmation modal
  };

  const confirmDelete = async () => {
    const reviewId = JSON.parse(sessionStorage.getItem('ReviewID'))
    await DeleteFileExEmployeeFile(fileToDelete, employee?.name || reviewId)
    if (fileToDelete === 'aadhar_card') {
      setadharcard("")
    } else if (fileToDelete === 'pan_card') {
      setpancard("")
    } else if (fileToDelete === 'voter_id') {
      setvoterid("")

    }
    setShowModal(false); // Close the modal
    setFileToDelete(null); // Reset fileToDelete
  };

  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const CustomUploadFile = async ({
    fileName,
    fileData,
    fieldName,
    fileKey,
  }) => {
    const res = await uploadFile({
      fileName,
      fileData,
      doctype: "Non Joiners Reviews",
      docname:
        employee?.name ||
        IDSave ||
        JSON.parse(sessionStorage.getItem("ReviewID")),
      fieldName: fieldName,
    });
    if (res.status === 200) {
      setFileNames((prevState) => ({
        ...prevState,
        [fileKey]: fileName,
      }));
    }else{
      toast.error("Please try again File is not uploaded", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setnewLoading({ fieldName: "", status: false });
      if (fileKey === "aadhar_card") {
        setadharcard("");
      } else if (fileKey === "voter_id") {
        setvoterid("");
      } else if (fileKey === "pan_card") {
        setpancard("");
      }
    }
  };
  
  const handleRemove = () => {
    validateFormik.resetForm();
    setHideEditButton(true);
    setFile(null);
    setFileNames({
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      file5: "",
    });
    setEmailError("");
  };

  const handleRemoveForm = () => {
    resetForm();
  };

  const resetForm = () => {
    validateFormik.resetForm();
    setIsEditing(false);
    setHideEditButton(true);
    setFile(null);
    setFileNames({
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      file5: "",
    });
    // setSelectID(undefined);
    setEmailError("");
  };

  const handleSave = async () => {
    const errors = await validateFormik.validateForm();

    if (Object.keys(errors).length > 0) {
      toast.error("Please correct the validation errors before saving.");
      return;
    }

    const payload = {
      data: {
        name: employee.name,
        first_name: validateFormik.values.first_name,
        // aadhar_card: validateFormik.values.aadhar_card || "",
        last_name: validateFormik.values.last_name || "",
        // pan_card: validateFormik.values.pan_card || "",
        date_of_birth: validateFormik.values.date_of_birth || "",
        other_id_proof_number:
          validateFormik.values.other_id_proof_number || "",
        enter_id_type: validateFormik.values.enter_id_type || "",
        phone: validateFormik.values.phone || "",
        email: validateFormik.values.email || "",
        designation: validateFormik.values.designation || "",
        review_details: validateFormik.values.review_details || "",

        company_name: validateFormik.values.company_name || "",
        location: validateFormik.values.location || "",
        joined_on: validateFormik.values.joined_on || "",
        released_on: validateFormik.values.released_on || "",
        previous_designation: validateFormik.values.previous_designation || "",

        highest_education: validateFormik.values.highest_education || "",
        schoolcollegeinstitute:
          validateFormik.values.schoolcollegeinstitute || "",
        boarduniversity: validateFormik.values.boarduniversity || "",
        city: validateFormik.values.city || "",
        state: validateFormik.values.state || "",
        year: validateFormik.values.year || undefined,
        doctype: "Non Joiners Reviews",
      },
    };

    try {
      const res = await updateNonJoiners(payload, employee.name);
      if (res.status === 200) {
        toast.success("Employee data updated successfully!");
        handleEditToggleButton()
        onFormSubmit();
      } else {
        toast.error("Employee data update error!");
        onFormSubmit();
      }
    } catch (error) {
      if(error?.response?.data?.message==="Phone no. already exist."){
        toast.error("Phone no. already exist.");
      }else{
        console.error("Error updating employee:", error);
        toast.error("Failed to update employee data.");
      }
    }
  };

  // const onFileChangeCapture = (e, filekey) => {
  //   setnewLoading({ fieldName: "user_image", status: true });
  //   const file = e.target.files[0];
  //   setFile(e.target.files);
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = function (theFile) {
  //     var image = new Image();
  //     image.src = theFile.target.result;
  //     imgRef.current.src = image.src;

  //     let binaryString = theFile.target.result;
  //     const fileData = btoa(binaryString);
  //     CustomUploadProfilePicFile({ fileName: file.name, fileData, fieldName: filekey });
  //   };
  // };
  const onFileChangeCapture = (e, filekey) => {
    setnewLoading({ fieldName: "user_image", status: true });
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
    const fileType = file.type;

    // Check if file is valid
    if (!allowedFileTypes.includes(fileType)) {
      toast.error("Only .jpg, .jpeg, and .png files are allowed.");
      setnewLoading({ fieldName: "user_image", status: false });
      return;
    }

    setFile(e.target.files);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (theFile) {
      const imageSrc = theFile.target.result;
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        imgRef.current.src = image.src;
        const base64Data = imageSrc.split(",")[1];
        CustomUploadProfilePicFile({
          fileName: file.name,
          fileData: base64Data,
          fieldName: filekey,
        });

        setnewLoading({ fieldName: "user_image", status: false });
      };
      image.onerror = () => {
        console.error("Failed to load image");
        setnewLoading({ fieldName: "user_image", status: false });
      };
    };

    reader.onerror = () => {
      console.error("Error reading file");
      setnewLoading({ fieldName: "user_image", status: false });
    };
  };

  const CustomUploadProfilePicFile = async ({
    fileName,
    fileData,
    fieldName,
    fileKey,
  }) => {
    const res = await uploadFile({
      fileName,
      fileData,
      doctype: "Non Joiners Reviews",
      docname:
        employee?.name ||
        IDSave ||
        JSON.parse(sessionStorage.getItem("ReviewID")),
      fieldName: fieldName,
    });
    if (res.status === 200) {
      setFileNames((prevState) => ({
        ...prevState,
        [fileKey]: fileName,
      }));
    }
    setnewLoading({ fieldName: "", status: false });
  };

  const triggerFile = (elementRef) => {
    elementRef.current.click();
  };

  const handleEditSubmit = () => {
    handleSubmission(validateFormik.values);
  };

  const handleToggleButton = () => {
    setShowButton(!showButton);
  };

  const handleEditToggleButton = () => {
    setHideEditButton(!hideEditButton);
    setEditReview(!editReview);
    setIsEditing(!isEditing);
  };

  const selectedIdHandler = (value) => {
    setSelectID(value);
  };

  // Helper function to generate year options
  const generateYearOptions = (startYear, endYear) => {
    let years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push({ value: year.toString(), label: year.toString() });
    }
    return years;
  };

  const yearOptions = generateYearOptions(1990, new Date().getFullYear());

  document.body.classList.add("non-joiners");
  const handleEmailChange = (e) => {
    validateFormik.handleChange(e);
    const email = e.target.value;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Invalid email");
    } else {
      setEmailError("");
    }
  };
  const handleClose = () => {
    onClose();
  };

  const handleDeleteRequestClick = () => {
    setShowModalForDocDelete(true); // Show the confirmation modal
  };

  const confirmDeleteForDocDelete = () => {
    setShowModalForDocDelete(false); // Close the modal
    handleDeleteRequest(); // Execute delete function
  };

  const cancelDeleteForDocDelete = () => {
    setShowModalForDocDelete(false); // Close the modal without deleting
  };

  const handleDeleteRequest = async () => {
    const user = JSON.parse(sessionStorage.getItem("usr"));
    const requestCreator = user.email;

    const payload = {
      data: {
        reference_doctype: "Non Joiners Reviews",
        reference_name: employee.name,
        status: "Requested",
        request_creator: requestCreator,
        request_type: "Delete",
        details: "",
      },
    };

    try {
      const res = await deleteReviewRequest(payload);
      if (res.status === 200) {
        toast.success("Review delete request Created", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
        // notification.success({
        //   message: "Review delete request Created",
        // });
        // handleClose();
        setTimeout(() => {
          handleClose();
        }, 2000);
      } else {
        notification.error({
          message: "Failed to create review delete request",
        });
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      notification.error({
        message: "Error deleting review",
      });
    }
  };

  const ApproveDeleteRequest = async () => {
    const payload = {
      data: {
        name: employee.name,
        status: "Approved",
        // request_type: "Delete",
      },
    };
    try {
      const res = await approveDeleteRequest(payload);
      if (res.status === 200) {
        notification.success({
          message: "Review delete request approved",
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error approving delete request:", error);
    }
  };

  return (
    <>
      <div className="add-review-form">
        <ToastContainer />
        <div className="">
          <div className="ex-employee-form">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                validateFormik.handleSubmit();
              }}
              action="#"
            >
              <div className="mt-2 sm:rounded-lg">
                {!employee && (
                  <h2 className="reviewheading">Non Joiner Review</h2>
                )}
                {employee && (
                  <div className="review-id flex justify-between">
                    <span className="text-xl">
                      <p className="pb-4 font-bold">
                        Review ID-{employee.name}
                      </p>
                    </span>
                    {hideEditButton &&
                      !isSuperadmin &&
                      roleprofile?.role_profile_name === "Semi-admin" &&
                      workflow_state ? (
                      <div>
                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            handleDeleteRequestClick();
                          }}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            handleEditToggleButton();
                          }}
                          disabled={!workflow_state}
                        >
                          Edit
                        </button>
                      </div>
                    ) : (
                      <>
                        {editReview && (
                          <div className="flex">
                            <button
                              type="button"
                              className="cancelled-btn"
                              onClick={() => {
                                onClose();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="saved-btn ml-4"
                              onClick={() => {
                                handleSave();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <section class="w-full">
                  <div class=" pr-0 mx-auto first-section">
                    <div class="lg:w-full md:w-2/3 mx-auto">
                      <div class="photo-update mb-2">
                        <h3>Basic</h3>
                        <div
                          className={`${isEditing
                            ? "Product-thumbnail"
                            : "Product-thumbnail notallowonpic"
                            }`}
                          onClick={() => triggerFile(inputFileRef)}
                        >
                          <Spin
                            spinning={
                              newloading.status &&
                                newloading.fieldName === "user_image"
                                ? true
                                : false
                            }
                          >
                            <img
                              src={
                                employee?.profile_pic
                                  ? `${Url}/${employee?.profile_pic}`
                                  : "../img-placeholder.svg"
                              }
                              className="w-100"
                              ref={imgRef}
                              alt="Profile"
                            />
                          </Spin>
                        </div>

                        <input
                          type="file"
                          ref={inputFileRef}
                          accept="image/*"
                          style={styles.input}
                          disabled={
                            employee?.name ? !isEditing : !IdGenerateIndicator
                          }
                          onChangeCapture={(e) =>
                            onFileChangeCapture(e, "profile_pic")
                          }
                        />
                      </div>
                      {/* Basic  */}
                      <div class="flex flex-wrap justify-between gap-8">
                        {/* first Name */}
                        <div class="field-container">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={validateFormik.values.first_name}
                            disabled={!isEditing || IdGenerateIndicator}
                            onChange={validateFormik.handleChange}
                            placeholder="First Name"
                          />
                          <label htmlFor="first_name" className="placeholders">
                            First Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.first_name
                              ? validateFormik.errors.first_name
                              : ""}
                          </p>
                        </div>

                        {/* Add  Aadhar Document */}
                        <div class="field-container">
                          <input
                            type="text"
                            id="enter_id_type"
                            name="enter_id_type"
                            value={validateFormik.values.enter_id_type}
                            disabled={!isEditing || IdGenerateIndicator}
                            onChange={validateFormik.handleChange}
                            placeholder="Enter ID Type"
                          />
                          <label
                            htmlFor="enter_id_type"
                            className="placeholders"
                          >
                            Enter ID Type
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.enter_id_type
                              ? validateFormik.errors.enter_id_type
                              : ""}
                          </p>
                        </div>

                        {/* Last Name */}
                        <div class="field-container">
                          <input
                            type="text"
                            name="last_name"
                            value={validateFormik.values.last_name}
                            // disabled={isReview ? true :false}
                            disabled={!isEditing || IdGenerateIndicator}
                            onChange={validateFormik.handleChange}
                            placeholder="Last Name"
                          />
                          <label htmlFor="last_name" className="placeholders">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.last_name
                              ? validateFormik.errors.last_name
                              : ""}
                          </p>
                        </div>

                        {/* Add Adharacrd Documet */}
                        <div className="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={
                                adharcard?.replace(/^\/files\//, "") ||
                                employee?.aadhar_card?.replace(
                                  /^\/files\//,
                                  ""
                                ) ||
                                "Add Document (e.g. ID proof, Experience letter, Resume)" // Default placeholder text
                              }
                              readOnly
                              placeholder=" "
                              className="text-input"
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              }
                            />
                            <div
                              className={`upload-button ${adharcard || employee?.aadhar_card
                                ? "delete-button"
                                : ""
                                }`}
                              onClick={() => {
                                if (isEditing) {
                                  if (adharcard || employee?.aadhar_card) {
                                    handleDeleteClick("aadhar_card"); // Show confirmation modal for delete
                                  } else {
                                    inputDocRefs[0].current.click(); // Trigger file input click if no file is uploaded
                                  }
                                }
                              }}
                              style={{
                                backgroundColor:
                                  adharcard || employee?.aadhar_card
                                    ? "red"
                                    : "",
                                opacity: isEditing
                                  ? adharcard || employee?.aadhar_card
                                    ? "1" // Fully opaque if editing and a file is present
                                    : "1" // Fully opaque if editing and no file is present
                                  : "0.5", // Semi-transparent if editing is not allowed
                                color:
                                  adharcard || employee?.aadhar_card
                                    ? isEditing
                                      ? "white"
                                      : "lightgray"
                                    : "",
                                cursor: isEditing ? "pointer" : "not-allowed", // Change cursor based on state
                              }}
                            >
                              {adharcard || employee?.aadhar_card
                                ? "Delete" // Show Delete if a file is uploaded
                                : newloading?.status &&
                                  newloading.fieldName === "aadhar_card"
                                  ? "Uploading..." // Show Uploading if file is in process
                                  : "Upload"}
                            </div>

                            <input
                              type="file"
                              id="fileInput1"
                              onChange={(e) => {
                                onFileChange(e, "aadhar_card")
                                e.target.value = "";
                              }
                              }
                              className="file-input"
                              ref={inputDocRefs[0]}
                              style={{ display: "none" }} // Hide the actual file input
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              } // Disable if editing is not allowed
                            />
                          </div>
                        </div>

                        <div class="field-container">
                          <input
                            type="text"
                            name="designation"
                            value={validateFormik.values.designation}
                            disabled={!isEditing || IdGenerateIndicator}
                            onChange={validateFormik.handleChange}
                            placeholder="Designation"
                          />
                          <label htmlFor="designation" className="placeholders">
                            Designation <span style={{ color: "red" }}>*</span>
                          </label>

                          <p style={{ color: "red" }}>
                            {validateFormik.errors.designation
                              ? validateFormik.errors.designation
                              : ""}
                          </p>
                        </div>

                        <div className="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={
                                pancard?.replace(/^\/files\//, "") ||
                                employee?.pan_card?.replace(/^\/files\//, "") ||
                                fileNames.file2 ||
                                "Add Document (e.g. ID proof, Experience letter, Resume)"
                              }
                              readOnly
                              placeholder=" "
                              className="text-input"
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              }
                            />
                            <div
                              className={`upload-button ${pancard || employee?.pan_card
                                ? "delete-button"
                                : ""
                                }`}
                              onClick={() => {
                                if (isEditing) {
                                  if (pancard || employee?.pan_card) {
                                    handleDeleteClick("pan_card"); // Show confirmation modal for delete
                                  } else {
                                    inputDocRefs[1].current.click(); // Trigger file input click if no file is uploaded
                                  }
                                }
                              }}
                              style={{
                                backgroundColor:
                                  pancard || employee?.pan_card ? "red" : "",
                                opacity: isEditing
                                  ? pancard || employee?.pan_card
                                    ? "1" // Fully opaque if editing and a file is present
                                    : "1" // Fully opaque if editing and no file is present
                                  : "0.5", // Semi-transparent if editing is not allowed
                                color:
                                  pancard || employee?.pan_card
                                    ? isEditing
                                      ? "white"
                                      : "lightgray"
                                    : "",
                                cursor: isEditing ? "pointer" : "not-allowed",
                              }}
                            >
                              {pancard || employee?.pan_card
                                ? "Delete"
                                : newloading?.status &&
                                  newloading.fieldName === "pan_card"
                                  ? "Uploading..."
                                  : "Upload"}
                            </div>

                            <input
                              type="file"
                              id="fileInput2"
                              onChange={(e) => {
                                onFileChange(e, "pan_card")
                                e.target.value = "";
                              }
                              }
                              className="file-input"
                              ref={inputDocRefs[1]}
                              style={{ display: "none" }} // Hide the actual file input
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              }
                            />
                          </div>
                        </div>

                        {/* Add Dob */}
                        <div class="field-container">
                          <input
                            type="text"
                            name="date_of_birth"
                            value={validateFormik.values.date_of_birth}
                            // disabled={isReview ? true :false}
                            disabled={
                              isReview || !isEditing || IdGenerateIndicator
                            }
                            onChange={validateFormik.handleChange}
                            placeholder="DOB"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                          />
                          <label
                            htmlFor="date_of_birth"
                            className="placeholders"
                          >
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.date_of_birth
                              ? validateFormik.errors.date_of_birth
                              : ""}
                          </p>
                        </div>

                        <div className="field-container">
                          <div className="upload-container">
                            <input
                              type="text"
                              value={
                                voterid?.replace(/^\/files\//, "") ||
                                employee?.voter_id?.replace(/^\/files\//, "") ||
                                "Add Document (e.g. ID proof, Experience letter, Resume)" // Default placeholder text
                              }
                              readOnly
                              placeholder=" "
                              className="text-input"
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              }
                            />
                            <div
                              className={`upload-button ${voterid || employee?.voter_id
                                ? "delete-button"
                                : ""
                                }`}
                              onClick={() => {
                                if (isEditing) {
                                  if (voterid || employee?.voter_id) {
                                    handleDeleteClick("voter_id"); // Show confirmation modal for delete
                                  } else {
                                    inputDocRefs[2].current.click(); // Trigger file input click if no file is uploaded
                                  }
                                }
                              }}
                              style={{
                                backgroundColor:
                                  voterid || employee?.voter_id ? "red" : "",
                                opacity: isEditing
                                  ? voterid || employee?.voter_id
                                    ? "1" // Fully opaque if editing and a file is present
                                    : "1" // Fully opaque if editing and no file is present
                                  : "0.5", // Semi-transparent if editing is not allowed
                                color:
                                  voterid || employee?.voter_id
                                    ? isEditing
                                      ? "white"
                                      : "lightgray"
                                    : "",
                                cursor: isEditing ? "pointer" : "not-allowed", // Change cursor based on state
                              }}
                            >
                              {voterid || employee?.voter_id
                                ? "Delete" // Show Delete if a file is uploaded
                                : newloading?.status &&
                                  newloading.fieldName === "voter_id"
                                  ? "Uploading..." // Show Uploading if file is in process
                                  : "Upload"}
                            </div>

                            <input
                              type="file"
                              id="fileInput3"
                              onChange={(e) => {
                                onFileChange(e, "voter_id")
                                e.target.value = "";
                              }
                              }
                              className="file-input"
                              ref={inputDocRefs[2]}
                              style={{ display: "none" }} // Hide the actual file input
                              disabled={
                                employee?.name
                                  ? !isEditing
                                  : !IdGenerateIndicator
                              } // Disable if editing is not allowed
                            />
                          </div>
                        </div>
                        <div class="flex flex-col field-container  gap-8">
                          {/* <ul>
                            {typeOfReviews.map((review, index) => (
                              <li key={index}>{review[0]}</li>
                            ))}
                          </ul>
                          <Select
                            options={typeOfReviews.data?.map((review) => ({
                              label: review.name,
                            }))}
                            value={selectID}
                            placeholder="Type of review"
                            onChange={selectedIdHandler}
                          /> */}
                          <div className="relative">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              value={validateFormik.values.phone}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing || IdGenerateIndicator}
                              onChange={validateFormik.handleChange}
                              placeholder="Phone"
                              inputMode="numeric"
                              pattern="\d*"
                              maxLength="10"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                            />
                            <label htmlFor="phone" className="placeholders">
                              Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.phone
                                ? validateFormik.errors.phone
                                : ""}
                            </p>
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={validateFormik.values.email}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing || IdGenerateIndicator}
                              // onChange={validateFormik.handleChange}
                              onChange={handleEmailChange}
                              placeholder="Email"
                            />
                            <label htmlFor="email" className="placeholders">
                              Email
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.email
                                ? validateFormik.errors.email
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div class="field-container">
                          <div class="relative">
                            <textarea
                              type="text"
                              id="review_details"
                              name="review_details"
                              rows="3"
                              value={validateFormik.values.review_details}
                              // disabled={isReview ? true :false}
                              disabled={!isEditing || IdGenerateIndicator}
                              onChange={validateFormik.handleChange}
                              placeholder="Review Details"
                            />
                            <label
                              htmlFor="review_details"
                              className="placeholders"
                            >
                              Review Details{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <p style={{ color: "red" }}>
                              {validateFormik.errors.review_details
                                ? validateFormik.errors.review_details
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* Last Known Company */}
              <div className="last-company">
                <h3>Last known company</h3>
              </div>
              <div className="verflow-x-auto sm:rounded-lg">
                <section class="text-gray-600 body-font relative first-section">
                  <div class="flex flex-wrap gap-8">
                    <div class="field-container">
                      <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        value={validateFormik.values.company_name}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="Company Name"
                      />
                      <label htmlFor="company_name" className="placeholders">
                        Company Name
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.company_name
                          ? validateFormik.errors.company_name
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        name="location"
                        value={validateFormik.values.location}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="Location"
                      />
                      <label htmlFor="location" className="placeholders">
                        Location
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.location
                          ? validateFormik.errors.location
                          : ""}
                      </p>
                    </div>

                    <div className="field-container">
                      <input
                        type="text"
                        name="previous_designation"
                        value={validateFormik.values.previous_designation}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="Previous Designation"
                      />
                      <label
                        htmlFor="previous_designation"
                        className="placeholders"
                      >
                        Previous Designation{" "}
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.previous_designation
                          ? validateFormik.errors.previous_designation
                          : ""}
                      </p>
                    </div>
                    <div class="flex field-container gap-5">
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="joined_on"
                          name="joined_on"
                          value={validateFormik.values.joined_on}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing || IdGenerateIndicator}
                          onChange={validateFormik.handleChange}
                          placeholder="Joined On"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        <label htmlFor="joined_on" className="placeholders">
                          Joined On
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.joined_on
                            ? validateFormik.errors.joined_on
                            : ""}
                        </p>
                      </div>
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="released_on"
                          name="released_on"
                          value={validateFormik.values.released_on}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing || IdGenerateIndicator}
                          onChange={validateFormik.handleChange}
                          placeholder="Released On"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                        />
                        <label htmlFor="released_on" className="placeholders">
                          Released On
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.released_on
                            ? validateFormik.errors.released_on
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* Education field */}
              <div className="education">
                <h3>Education</h3>
              </div>

              <div className="verflow-x-auto sm:rounded-lg">
                <section class="text-gray-600 body-font">
                  <div class="flex flex-wrap gap-8 first-section education-section">
                    <div class="field-container">
                      <input
                        type="text"
                        id="highest_education"
                        name="highest_education"
                        value={validateFormik.values.highest_education}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="Highest Education"
                      />
                      <label
                        htmlFor="highest_education"
                        className="placeholders"
                      >
                        Highest Education{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.highest_education
                          ? validateFormik.errors.highest_education
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        id="schoolcollegeinstitute"
                        name="schoolcollegeinstitute"
                        value={validateFormik.values.schoolcollegeinstitute}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="School/College/Institution"
                      />
                      <label
                        htmlFor="schoolcollegeinstitute"
                        className="placeholders"
                      >
                        School/College/Institution{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.schoolcollegeinstitute
                          ? validateFormik.errors.schoolcollegeinstitute
                          : ""}
                      </p>
                    </div>
                    <div class="field-container">
                      <input
                        type="text"
                        id="boarduniversity"
                        name="boarduniversity"
                        value={validateFormik.values.boarduniversity}
                        // disabled={isReview ? true :false}
                        disabled={!isEditing || IdGenerateIndicator}
                        onChange={validateFormik.handleChange}
                        placeholder="Board/University"
                      />
                      <label htmlFor="boarduniversity" className="placeholders">
                        Board/University <span style={{ color: "red" }}>*</span>
                      </label>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.boarduniversity
                          ? validateFormik.errors.boarduniversity
                          : ""}
                      </p>
                    </div>

                    <div class="flex field-container gap-5">
                      <div className="relative w-1/2 ">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={validateFormik.values.city}
                          onChange={validateFormik.handleChange}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing || IdGenerateIndicator}
                          placeholder="City"
                        />
                        <label htmlFor="city" className="placeholders">
                          City <span style={{ color: "red" }}>*</span>
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.city
                            ? validateFormik.errors.city
                            : ""}
                        </p>
                      </div>
                      <div className="relative w-1/2">
                        <input
                          type="text"
                          id="state"
                          name="state"
                          value={validateFormik.values.state}
                          onChange={validateFormik.handleChange}
                          // disabled={isReview ? true :false}
                          disabled={!isEditing || IdGenerateIndicator}
                          placeholder="State"
                        />
                        <label htmlFor="state" className="placeholders">
                          State <span style={{ color: "red" }}>*</span>
                        </label>
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.state
                            ? validateFormik.errors.state
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="field-container">
                      <Select
                        id="year"
                        name="year"
                        value={validateFormik.values.year}
                        onChange={(value) =>
                          validateFormik.setFieldValue("year", value)
                        }
                        disabled={!isEditing || IdGenerateIndicator}
                        placeholder={
                          <>
                            Year <span style={{ color: "red" }}>*</span>
                          </>
                        }
                        style={{ width: "100%" }}
                        required
                      >
                        <label htmlFor="year" className="placeholders">
                          Year <span style={{ color: "red" }}>*</span>
                        </label>
                        {yearOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <p style={{ color: "red" }}>
                        {validateFormik.errors.year
                          ? validateFormik.errors.year
                          : ""}
                      </p>
                    </div>
                  </div>
                </section>
              </div>

              {/*footer*/}

              {!employee || isSuperadmin ? (
                <>
                  {/* <Checkbox>Disable</Checkbox> */}
                  <div className="flex items-center justify-end px-9 pb-8 review-bottom-btns">
                    {isSuperadmin ? (
                      <>
                        <button
                          type="button"
                          className="edited-btn ml-4"
                          onClick={() => {
                            ApproveDeleteRequest();
                          }}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="cancelled-btn ml-4"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      // If not Superadmin and employee is falsy, show Reset and Submit buttons
                      <>
                        <button
                          type="button"
                          className="reset-button"
                          onClick={() => {
                            handleRemove();
                          }}
                          disabled={IdGenerateIndicator}
                        >
                          Reset
                        </button>

                        {IdGenerateIndicator ? (
                          <button
                            type="submit"
                            disabled={loading}
                            className="dark-mode submit-btn"
                            onClick={() => {
                              validateFormik.setFieldValue(
                                "submitAction",
                                "submit"
                              );
                            }}
                          >
                            <span>Submit</span>
                            {loading && (
                              <span className="spinner-border spinner-border-sm">
                                .....
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={loading}
                            className="dark-mode submit-btn"
                            onClick={() => {
                              validateFormik.setFieldValue(
                                "submitAction",
                                "next"
                              );
                              validateFormik.handleSubmit();
                            }}
                          >
                            <span>Next</span>
                            {loading && (
                              <span className="spinner-border spinner-border-sm">
                                .....
                              </span>
                            )}
                          </button>
                        )}

                        {/* <button
                          type="submit"
                          disabled={loading}
                          className="dark-mode submit-btn"
                        >
                          <span>Submit</span>
                          {loading && (
                            <span className="spinner-border spinner-border-sm">
                              .....
                            </span>
                          )}
                        </button> */}
                      </>
                    )}
                  </div>
                </>
              ) : null}
            </form>
          </div>
        </div>
      </div>{" "}
      {showModal && (
        <ConfirmationModal
          isOpen={showModal}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          fileToDelete={fileToDelete}
        />
      )}
      {showModalForDocDelete && (
        <ConfirmationModal
          isOpen={showModal}
          onConfirm={confirmDeleteForDocDelete}
          onCancel={cancelDeleteForDocDelete}
          fileToDelete={fileToDelete}
        />
      )}{" "}
    </>
  );
};

export default AddNonJoiners;
