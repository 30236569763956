import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthHeader, { searchUsers } from "../../../Services/authHeader";
import SuperAdminService, {
  getCompanyDetails,
} from "../../../Services/superadminService";
import { useNavigate } from "react-router-dom";
import Image from "../../../Assets/Images/close.png";
import { format } from "date-fns";
import { MdNavigateNext } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../loader";
import { Layout, Table, Button } from "antd";
import "../styles.css";
import { Content } from "antd/es/layout/layout";
import SuperAdminNav from "../../../Widgets/SuperadmincommonNav";
import Sider from "antd/es/layout/Sider";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import CompanyDetails from "./company-details";
import FilterIcon from "../../../../src/Assets/Images/filter-icon.svg";
import DownArrow from "../../../../src/Assets/Images/down-arrow.svg";
import { PageConfig } from "../../../Services/Context";

const Approve = () => {
  const [showTable, setShowTable] = useState(true); // State to toggle between table and details view
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null); // State to hold the selected company details
  const [currentPage, setCurrentPage] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const { refine } = PageConfig()
  const [searchData,setsearchData]=useState([])
  const recordsPerPage = 10;
  let totalPages = 0;
 
  // console.log("##############",selectedCompany)
  async function getCompany() {
    try {
      let data = await SuperAdminService.getpendingCompanyforSuperadmin();
      totalPages = Math.ceil(data.length / recordsPerPage);
      data.map((item) => {
        item.creation2 = format(new Date(item?.creation), "MMM dd, yyyy");
      });
      setCompanyData(data);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    getCompany();
  }, [refine]);

  async function fetchCompanyDetails(companyName) {
    try {
      const companyDetails = await getCompanyDetails(companyName);
      setSelectedCompany(companyDetails);
      setShowTable(false); // Hide table and show details
    } catch (error) {
      toast.error("Failed to fetch company details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
 
  const handleBackToTable = () => {
    setShowTable(true);
    setSelectedCompany(null); // Reset selected company details
    setsearchData([])
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      getCompany();
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getCompany();
    }
  };
  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  const columns = [
    { title: "Company Name", dataIndex: "company", key: "company" },
    { title: "Email", dataIndex: "owner", key: "owner" },
    { title: "User Name", dataIndex: "name", key: "name" },
    {
      title: "Created on",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    { title: "Phone", dataIndex: "buisness_phone", key: "buisness_phone" },
    { title: "Industry", dataIndex: "industry", key: "industry" },
    { title: "Status", dataIndex: "workflow_state", key: "workflow_state" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => fetchCompanyDetails(record.name)}>View</Button>
      ),
    },
  ];
 
  document.body.classList.add("approve-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "issue-page",
    "search-page",
    "users-page",
    "reports-page",
    "companyreportsave-page",
    "review-page",
    "deletion-page",
    "billing-page"
  );

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const emailInputRef = useRef(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const onlyNums = value.replace(/[^0-9]/g, '');
      if (onlyNums.length <= 10) {
        setFormData({
          ...formData,
          [name]: onlyNums,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleClear = () => {
    setFormData({
      name: '',
      phone: '',
      email: ''
    });
    setsearchData([])
  };

  // Regex Search Implementation
  const regexSearch = (arr, key, value) => {
    const regex = new RegExp(value, 'i'); // 'i' for case-insensitive search
    return arr.filter((item) => item[key] && regex.test(item[key]));
  };

  const handleSearch = async () => {
    const emailError = validateEmail(formData.email);

    try {
      // Check if any field is filled
      if (!formData.name && !formData.phone && !formData.email) {
        toast.error('Please enter a name, phone, or email to search', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // Check if email is invalid (only if it's filled)
      if (formData.email && emailError) {
        toast.error(emailError, {
          position: toast.POSITION.TOP_RIGHT,
        });
        emailInputRef.current.focus();
        return;
      }

      let searchKey;
      let searchValue;

      if (formData.email) {
        searchKey = 'buisness_email';
        searchValue = formData.email;
      } else if (formData.phone) {
        searchKey = 'buisness_phone';
        searchValue = formData.phone;
      } else {
        searchKey = 'company';
        searchValue = formData.name;
      }

      // Perform Regex Search
      const results = regexSearch(companyData, searchKey, searchValue);
      if (results.length > 0) {
        setsearchData(results)
        // console.log('Search Results:', results);
      } else {
        toast.error('No record found', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    } catch (err) {
      console.log("Error in handleSearch:", err);
    }
  };


  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            {showTable ? (
              <div className="w-auto" style={{ paddingTop: "2.5rem" }}>
                <div className="search-right">
                  <div className="search-fields">
                    <div className="flex justify-between mb-8 search-table-section">
                      <div className="form-fields">
                        <input
                          type="text"
                          id="first_name"
                          name="name"
                          placeholder="Search Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-fields">
                        <input
                          type="text"
                          id="phone"
                          placeholder="Search Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          maxLength="10"
                        />
                      </div>
                      <div className="form-fields">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Search Email"
                          value={formData.email}
                          onChange={handleChange}
                          ref={emailInputRef} 
                        />
                      </div>

                      {/* <div className="pr-0">
                        <button
                          className={`flex border p-2 rounded-md more-filter-btn ${
                            isExpanded ? "expanded" : ""
                          }`}
                          type="button"
                          onClick={handleButtonClick}
                        >
                          <img
                            src={FilterIcon}
                            alt=""
                            style={{
                              filter: isExpanded
                                ? "invert(1) brightness(1000%)"
                                : "none",
                            }}
                          />
                          More Filter
                          <img
                            src={DownArrow}
                            alt=""
                            style={{
                              filter: isExpanded
                                ? "invert(1) brightness(1000%)"
                                : "none",
                            }}
                          />
                        </button>
                      </div> */}


                      <div className="search-btn-cont">
                        <button className="clear-btn" onClick={handleClear}>Clear</button>
                        <button onClick={handleSearch}>Search</button>
                      </div>
                      
                    </div>
                  </div>
                </div>
                {/* Table View */}
                <div className=" approve-table-div ">
                  {companyData && companyData.length > 0 ? (
                    <>
                      <div className="result-table-head">
                        <h2>Results</h2>
                        {/* <div className="right-head">
                          <Button type="primary" htmlType="submit" className="btn btn-primary dark-btn">
                            Create New
                          </Button>
                        </div> */}
                      </div>
                      <Table
                        columns={columns}
                        dataSource={searchData.length>0 ? searchData : companyData}
                        pagination={{
                          defaultPageSize: 10,
                          pageSizeOptions: ["10", "20", "30", "50"],
                          showSizeChanger: true,
                        }}
                        rowKey="id"
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
                <ToastContainer />
              </div>
            ) : (
              <CompanyDetails
                selectedCompany={selectedCompany}
                handleBackToTable={handleBackToTable}
              />
            )}
          </Content>
        </Layout>
      </Layout >
    </>
  );
};

export default Approve;
