import React, { useEffect, useState } from "react";
import {
  contactList,
  contactUs,
  contactUsStatus,
  fetchRegions,
} from "../../../Services/authHeader";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar";
import { Layout, Input, Form, Button, message, Table, Select } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import FilterIcon from "../../../../src/Assets/Images/filter-icon.svg";
import DownArrow from "../../../../src/Assets/Images/down-arrow.svg";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import { toast } from "react-toastify";
import { PageConfig } from "../../../Services/Context";

const Issue = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [regions, setRegions] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [getSearchUser, setGetSearchUser] = useState({
    username: "",
    phone: "",
    email: "",
    creation: "",
    company: "",
    designation: "",
  });
  const { setDropdownVisible} = PageConfig();

  useEffect(()=>{
    setDropdownVisible(false)
  },[])


  const fetchContacts = async () => {
    try {
      const data = await contactList();
      setContacts(data);
    } catch (error) {
      console.error("Failed to fetch contact list", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
    const fetchRegionsData = async () => {
      try {
        const regionsData = await fetchRegions();
        setRegions(regionsData);
      } catch (error) {
        console.error("Failed to fetch regions", error);
      }
    };

    fetchRegionsData();
  }, []);

  const handleViewIssue = (record) => {
    setSelectedContact(record);
    setShowForm(true);
  };

  const handleBackToTable = () => {
    setSelectedContact(null);
    setShowForm(false);
  };

  const handleFormSubmit = async (values) => {
    console.log("Submitting form with values:", values);

    if (!values.workflow_state) {
      console.error("Workflow state is not selected or is missing.");
      return;
    }

    try {
      if (selectedContact) {
        // Update existing issue
        const payload = {
          doc: {
            name: selectedContact.name,
            workflow_state: values.workflow_state,
            full_name: values.full_name,
            email: values.email,
            phone: values.phone,
            region: values.region,
            details: values.details,
            doctype: "Contact Us",
            __last_sync_on: new Date().toISOString(),
          },
          action: values.workflow_state === "Approved" ? "Approve" : "Submit",
        };
        await contactUsStatus(payload);
        message.success("Issue status updated successfully", 3);
      } else {
        // Create new issue
        await contactUs(
          values.full_name,
          values.email,
          values.phone,
          values.region,
          values.details,
          values.workflow_state
        );
        message.success("Issue created successfully", 3);
      }

      fetchContacts();
      handleBackToTable();
    } catch (error) {
      console.error("Failed to submit contact details", error);
      message.error("Failed to submit contact details", 3);
    }
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  const handleSearchUser = (e) => {
    const { name, value } = e.target;
    setGetSearchUser({ ...getSearchUser, [name]: value });
  };
  const columns = [
    {
      title: "Issue ID",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Creation Date",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Submitted By",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "workflow_state",
      key: "workflow_state",
      render: (status) => (
        <span style={{ color: status === "Pending" ? "orange" : "green" }}>
          {status}
        </span>
      ),
    },
    {
      title: "View",
      key: "View",
      render: (text, record) => (
        <a
          href="#!"
          onClick={() => handleViewIssue(record)}
          className="view-link"
        >
          View
        </a>
      ),
    },
  ];
  const filterArrayOfObject = (arr, filters) => {
    return arr.filter((obj) => {
      return Object.keys(filters).every((key) => {
        if (
          filters[key] === undefined ||
          filters[key] === null ||
          filters[key] === ""
        ) {
          return true;
        }
        if (obj[key] === undefined || obj[key] === null) {
          return false;
        }

        // Handle date comparison
        if (key === "creation") {
          const filterDate = new Date(filters[key]).toISOString().split("T")[0];
          const objDate = new Date(obj[key]).toISOString().split("T")[0];
          return objDate.includes(filterDate);
        }

        return obj[key]
          .toString()
          .toLowerCase()
          .includes(filters[key].toString().toLowerCase());
      });
    });
  };
  const searchAndSetResult = () => {
    const filters = {
      name: getSearchUser.username,
      phone: getSearchUser.phone,
      email: getSearchUser.email,
      creation: getSearchUser.creation,
      workflow_state: getSearchUser.workflow_state,
    };

    const filteredData = filterArrayOfObject(contacts, filters);

    if (filteredData.length === 0) {
      toast.info("No Match Found!");
    }

    setFilterUser(filteredData);
  };

  const clearFilters = () => {
    setGetSearchUser({
      username: "",
      phone: "",
      email: "",
      creation: "",
      workflow_state: "",
    });
    setFilterUser([]);
  };

  document.body.classList.add("issue-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page",
    "reports-page",
    "companyreportsave-page",
    "review-page",
    "approve-page",
    "deletion-page"
  );

  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            <div className="w-auto" style={{ paddingTop: "2.5rem" }}>
              {showForm ? (
                <div className="form-container">
                  <Form
                    layout="vertical"
                    initialValues={
                      selectedContact || {
                        full_name: "",
                        email: "",
                        phone: "",
                        region: "",
                        details: "",
                        workflow_state: "Pending", // Default to Pending if new
                      }
                    }
                    onFinish={handleFormSubmit}
                  >
                    <div className="form-head">
                      <h2>
                        {selectedContact
                          ? `Issue ID - ${selectedContact.name}`
                          : "Create Issue ID"}
                      </h2>
                      <div
                        className="right-btns"
                        style={{ display: "flex", gap: "1rem" }}
                      >
                        <Button
                          type="default"
                          onClick={handleBackToTable}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn btn-primary dark-btn"
                        >
                          {selectedContact ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                    <div className="formbody">
                      <Form.Item
                        name="full_name"
                        label="Full Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your full name",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number",
                          },
                          {
                            pattern: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit phone number",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email address",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name="workflow_state"
                        value="workflow_state"
                        label="Status"
                        rules={[
                          {
                            required: true,
                            message: "Please select the status",
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value="Pending">Pending</Select.Option>
                          <Select.Option value="Approved">
                            Approved
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="region"
                        label="Region"
                        rules={[
                          { required: true, message: "Please select a region" },
                        ]}
                      >
                        <Select
                          disabled
                          options={regions.map((region) => ({
                            label: region.region_name,
                            value: region.region_name,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        name="details"
                        label="Details"
                        rules={[
                          { required: true, message: "Please enter details" },
                        ]}
                      >
                        <Input.TextArea rows={6} disabled />
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              ) : (
                // Table view
                <div className="w-auto" style={{ paddingTop: "2.5rem" }}>
                  <div className="search-right">
                    <div className="search-fields">
                      <div className="flex justify-between mb-8 search-table-section">
                        <div className="form-fields">
                          <input
                            type="text"
                            id="first_name"
                            name="username"
                            placeholder="Search Issue ID"
                            value={getSearchUser.username}
                            onChange={handleSearchUser}
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            id="phone"
                            placeholder="Search Phone Number"
                            name="phone"
                            value={getSearchUser.phone}
                            onChange={handleSearchUser}
                          />
                        </div>
                        <div className="form-fields">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Search Submitted By"
                            value={getSearchUser.email}
                            onChange={handleSearchUser}
                          />
                        </div>
                        <div className="pr-0">
                          <button
                            className={`flex border p-2 rounded-md more-filter-btn ${
                              isExpanded ? "expanded" : ""
                            }`}
                            type="button"
                            onClick={handleButtonClick}
                          >
                            <img
                              src={FilterIcon}
                              alt=""
                              style={{
                                filter: isExpanded
                                  ? "invert(1) brightness(1000%)"
                                  : "none",
                              }}
                            />
                            More Filter
                            <img
                              src={DownArrow}
                              alt=""
                              style={{
                                filter: isExpanded
                                  ? "invert(1) brightness(1000%)"
                                  : "none",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                      {isExpanded && (
                        <>
                          <div className="expand-box">
                            <div className="flex justify-between mb-8 search-table-section">
                              <div className="form-fields">
                                <Select
                                  placeholder="Select Status"
                                  value={getSearchUser.workflow_state}
                                  onChange={(value) =>
                                    setGetSearchUser({
                                      ...getSearchUser,
                                      workflow_state: value,
                                    })
                                  }
                                  className="search-select"
                                >
                                  <Select.Option value="Pending">
                                    Pending
                                  </Select.Option>
                                  <Select.Option value="Approved">
                                    Approved
                                  </Select.Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="search-btn-cont">
                        <button onClick={clearFilters} className="clear-btn">
                          Clear
                        </button>
                        <button onClick={searchAndSetResult}>Search</button>
                      </div>
                    </div>
                  </div>
                  <div className="issue-div">
                    <div className="result-table-head">
                      <h2>Contact Us</h2>
                      <div className="right-btn">
                        <button
                          type="button"
                          className="btn btn-primary dark-btn"
                          onClick={() => {
                            setShowForm(true);
                            setSelectedContact(null); // Clear selected contact for creating new
                          }}
                        >
                          Create New
                        </button>
                      </div>
                    </div>
                    <Table
                      columns={columns}
                      dataSource={filterUser.length > 0 ? filterUser : contacts}
                      loading={loading}
                      rowKey="id"
                      pagination={{ pageSize: 10 }}
                      className="approve-table-div"
                    />
                  </div>
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Issue;
