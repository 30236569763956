import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sider from "antd/es/layout/Sider";
import { Layout, Table, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar";
import { getApprovedCompanies } from "../../../Services/superadminService";
import CompanyDetails from "./company-details";
import FilterIcon from "../../../../src/Assets/Images/filter-icon.svg";
import DownArrow from "../../../../src/Assets/Images/down-arrow.svg";
import AddCompany from "./createCompany";

const CompanyApprovedList = () => {
  const [company, setCompany] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setisOpen] = useState(false)

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });
  const [searchData, setsearchData] = useState([])

  const emailInputRef = useRef(null);

  // const [getSearchUser, setGetSearchUser] = useState({
  //   company: "",
  //   phone: "",
  //   email: "",
  // });

  useEffect(() => {
    fetchApprovedCompanies();
  }, []);

  const fetchApprovedCompanies = async () => {
    try {
      const res = await getApprovedCompanies();
      setCompany(res);
      setFilteredCompanies(res);
      console.log(res, "Successfully fetched companies");
    } catch (error) {
      toast.error("Error fetching companies");
      console.error("Error fetching companies:", error);
    }
  };

  // const handleSearchUser = (e) => {
  //   const { name, value } = e.target;
  //   setGetSearchUser({ ...getSearchUser, [name]: value });
  //   filterCompanies({ ...getSearchUser, [name]: value });
  // };

  // const filterCompanies = (searchValues) => {
  //   const { company, phone, email } = searchValues;

  //   const filtered = company.filter((comp) => {
  //     return (
  //       comp.company.toLowerCase().includes(company.toLowerCase()) &&
  //       comp.buisness_phone.includes(phone) &&
  //       comp.buisness_email.toLowerCase().includes(email.toLowerCase())
  //     );
  //   });
  //   setFilteredCompanies(filtered);
  // };

  const handleViewCompany = (record) => {
    setSelectedCompany(record);
    setShowForm(true);
  };

  const handleBackToTable = () => {
    setSelectedCompany(null);
    setShowForm(false);
    setsearchData([])
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const columns = [
    { title: "Company Name", dataIndex: "company", key: "company" },
    {
      title: "Email",
      dataIndex: "buisness_email",
      key: "buisness_email",
    },
    {
      title: "Created By",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Creation On",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    { title: "Phone", dataIndex: "buisness_phone", key: "buisness_phone" },
    // {
    //   title: "Submissions",
    //   dataIndex: "no_of_employees",
    //   key: "no_of_employees",
    // },
    { title: "Industry", dataIndex: "industry", key: "industry" },
    {
      title: "Status",
      dataIndex: "workflow_state",
      key: "workflow_state",
      render: (workflow_state) => workflow_state === "Draft" ? "Submitted" : workflow_state,
    },

    {
      title: "Action",
      key: "View",
      render: (text, record) => (
        <a href="#!" onClick={() => handleViewCompany(record)}>
          View
        </a>
      ),
    },
  ];

  // document.body.classList.add("approve-page");
  document.body.classList.add("approve-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "issue-page",
    "search-page",
    "users-page",
    "reports-page",
    "companyreportsave-page",
    "review-page",
    "deletion-page",
    "billing-page"
  );

  // ----------------------------------------------------


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const onlyNums = value.replace(/[^0-9]/g, '');
      if (onlyNums.length <= 10) {
        setFormData({
          ...formData,
          [name]: onlyNums,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleClear = () => {
    setFormData({
      name: '',
      phone: '',
      email: ''
    });
    setsearchData([])
  };

  // Regex Search Implementation
  const regexSearch = (arr, key, value) => {
    const regex = new RegExp(value, 'i'); // 'i' for case-insensitive search
    return arr.filter((item) => item[key] && regex.test(item[key]));
  };

  const handleSearch = async () => {
    const emailError = validateEmail(formData.email);

    try {
      // Check if any field is filled
      if (!formData.name && !formData.phone && !formData.email) {
        toast.error('Please enter a name, phone, or email to search', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // Check if email is invalid (only if it's filled)
      if (formData.email && emailError) {
        toast.error(emailError, {
          position: toast.POSITION.TOP_RIGHT,
        });
        emailInputRef.current.focus();
        return;
      }

      let searchKey;
      let searchValue;

      if (formData.email) {
        searchKey = 'buisness_email';
        searchValue = formData.email;
      } else if (formData.phone) {
        searchKey = 'buisness_phone';
        searchValue = formData.phone;
      } else {
        searchKey = 'company';
        searchValue = formData.name;
      }

      // Perform Regex Search
      const results = regexSearch(filteredCompanies, searchKey, searchValue);
      if (results.length > 0) {
        setsearchData(results)
      } else {
        toast.error('No record found', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    } catch (err) {
      console.log("Error in handleSearch:", err);
    }
  };

  const handleClose=()=>{
    setisOpen(false);
    const darkModeElement = document.getElementById("dark-mode-on");
    if (darkModeElement) {
      darkModeElement.classList.remove("dark-mode-on");
    }
  }


  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        <Sider width={256} className="site-layout-background">
          <SuperAdminSideNavbar />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            <div className="w-auto" style={{ paddingTop: "2.5rem" }}>
              <div className="search-right">
                <div className="search-fields">
                  {!showForm && <div className="flex justify-between mb-8 search-table-section">
                    <div className="form-fields">
                      <input
                        type="text"
                        id="company_name"
                        name="name"
                        placeholder="Search Company Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Search Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength="10"
                      />
                    </div>
                    <div className="form-fields">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Search Email"
                        value={formData.email}
                        onChange={handleChange}
                        ref={emailInputRef}
                      />
                    </div>

                    {/* <div className="pr-0">
                      <button
                        className={`flex border p-2 rounded-md more-filter-btn ${
                          isExpanded ? "expanded" : ""
                        }`}
                        type="button"
                        onClick={handleButtonClick}
                      >
                        <img
                          src={FilterIcon}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        />
                        More Filter
                        <img
                          src={DownArrow}
                          alt=""
                          style={{
                            filter: isExpanded
                              ? "invert(1) brightness(1000%)"
                              : "none",
                          }}
                        />
                      </button>
                    </div> */}

                    <div className="search-btn-cont">
                      <button className="clear-btn" onClick={handleClear}>Clear</button>
                      <button onClick={handleSearch}>Search</button>
                    </div>
                  </div>
                  }
                </div>
              </div>
              {showForm ? (
                <CompanyDetails
                  selectedCompany={selectedCompany}
                  handleBackToTable={handleBackToTable}
                />
              ) : (
                <>
                  <div className="result-table-head">
                    <h2>Results</h2>

                    <div className="right-head">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn btn-primary dark-btn"
                        onClick={() => setisOpen(true)}
                      >
                        Create New
                      </Button>
                      {
                        isOpen && (
                          <AddCompany
                            handleClose={handleClose}
                            // onSuccess={handleAddUserSuccess} // Pass success callback
                            // userCompany={userCompany}
                          />
                        )
                      }
                    </div>

                  </div>
                  <Table
                    dataSource={searchData.length > 0 ? searchData : filteredCompanies}
                    columns={columns}
                    rowKey="name"
                  />
                </>
              )}
            </div>
            <ToastContainer />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default CompanyApprovedList;
