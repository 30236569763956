import React, { createContext, useContext, useState } from 'react'

export const AuthContext = createContext();


export const ContextProvider = ({ children }) => {
    const [active, setactive] = useState(false);
    const [closeform, setcloseform] = useState(null)
    const [TypeTab, setTypeTab] = useState(null)
    const [refine,setrefine]=useState(false)
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isApprove, setisApprove] = useState(false);

    return (
        <AuthContext.Provider value={{ active, setactive, closeform, setcloseform, setTypeTab,TypeTab,refine,setrefine ,isDropdownVisible, setDropdownVisible , isApprove, setisApprove }}>
            {children}
        </AuthContext.Provider>
    )
}

export const PageConfig = () => {
    return useContext(AuthContext)
}
