import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
// import "bootstrap-icons/font/bootstrap-icons.css"
import logoWhite from "../Assets/Images/white.png";
import toggleicon from "../Assets/Images/toogle-icon.png";
import ToggleSideBar from "../Assets/Images/sidebar-toggle.svg";
import { PageConfig } from "../Services/Context";
function SideNav() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const navigate=useNavigate()
 
  const { isDropdownVisible, setDropdownVisible} = PageConfig();

  const handleExpandButton=()=>{
    setDropdownVisible(true)
    navigate('/superAdmin/CompanyApproveRequest')
  }


  return (
    <>
      <img class="w-8 h-8 " src={toggleicon} alt="" className="sidebar-logo" />
      <button
        onClick={toggleMenu}
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        class="inline-flex items-center mt-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 sidebar-logo"
      >
        <span class="sr-only">Open sidebar</span>
        <img
          class="w-8 h-8 "
          src={ToggleSideBar}
          alt=""
          style={{
            filter: "invert(1) brightness(1000%)",
          }}
        />
      </button>

      <div
        id="logo-sidebar"
        className={`top-0 left-0 z-40 w-64 h-full transition-transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        <div class="h-screen px-3 py-4 overflow-y-auto bg-gray-900 dark:bg-gray-800 ">
          <>
            <button
              onClick={toggleMenu}
              type="button"
              data-drawer-hide="drawer-navigation"
              aria-controls="drawer-navigation"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white close-btn"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close menu</span>
            </button>
            <Link
              to="/Dashboard"
              className="flex items-center p-2 text-base font-normal group"
            >
              <img
                src={logoWhite}
                className="mr-3 h-5 sm:h-9"
                alt="Flowbite Logo"
                class="white-logo"
              />
            </Link>
            <div className="side-navbar pt-4">
              <ul class="space-y-2 font-medium">
                <li className="dash-nav-link">
                  <Link to="/superAdmin/Dashboard" className="HomeDashboard">
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="home"
                    >
                      <path
                        d="M10.3905 1.1464L1.24699 8.98368L1.15198 9.06512L1.05718 9.14638L0.918651 9.26512L0.875 9.30253V9.36003V9.54169V9.66669V9.79169V18.875V19V19.125H0.878676C0.909179 19.643 1.12845 20.1337 1.4974 20.5026C1.86635 20.8716 2.357 21.0908 2.875 21.1213V21.125H3H3.125H8.20833H8.33333H8.45833V21.1151C8.62198 21.0889 8.77445 21.0118 8.89313 20.8931C9.0118 20.7745 9.08891 20.622 9.11507 20.4583H9.125V20.3333V20.2083V16.4583V16.3333C9.125 15.8361 9.32254 15.3591 9.67418 15.0075C10.0258 14.6559 10.5027 14.4583 11 14.4583C11.4973 14.4583 11.9742 14.6559 12.3258 15.0075C12.6775 15.3591 12.875 15.8361 12.875 16.3333V16.4583V20.2083V20.3333V20.4583H12.8849C12.9111 20.622 12.9882 20.7745 13.1069 20.8931C13.2255 21.0118 13.378 21.0889 13.5417 21.1151V21.125H13.6667H13.7917H18.875H19H19.125V21.1213C19.643 21.0908 20.1336 20.8716 20.5026 20.5026C20.8716 20.1337 21.0908 19.643 21.1213 19.125H21.125V19V18.875V9.79169V9.66669V9.54169V9.36003V9.30253L21.0813 9.26512L20.9428 9.14638L20.848 9.06512L20.753 8.98368L11.6095 1.1464L11.6096 1.1463L11.5147 1.06514L11.5146 1.06505C11.5145 1.06498 11.5144 1.0649 11.5143 1.06483L11.4197 0.983699L11.4131 0.991344C11.2894 0.91568 11.1466 0.875 11 0.875C10.8534 0.875 10.7106 0.91568 10.5869 0.991344L10.5803 0.983699L10.4854 1.06505L10.4853 1.06514L10.3904 1.1463L10.3905 1.1464ZM11 2.70944L19.5417 10.0308V18.875V19C19.5417 19.1437 19.4846 19.2814 19.383 19.383C19.2814 19.4846 19.1437 19.5417 19 19.5417H18.875H14.4583V16.4583V16.3333V16.2083H14.4561C14.4246 15.3365 14.0645 14.507 13.4454 13.8879C12.8264 13.2689 11.9968 12.9088 11.125 12.8773V12.875H11H10.875V12.8773C10.0032 12.9088 9.17363 13.2689 8.55459 13.8879C7.93555 14.507 7.57543 15.3365 7.54392 16.2083H7.54167V16.3333V16.4583V19.5417H3.125H3C2.85634 19.5417 2.71857 19.4846 2.61698 19.383C2.5154 19.2814 2.45833 19.1437 2.45833 19V18.875V10.0308L11 2.70944Z"
                        fill="#68859B"
                        stroke="#68859B"
                        stroke-width="0.25"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Home
                    </span>
                  </Link>
                </li>

                <li className="dash-nav-link">
                  <button
                    className="companies drop-button flex items-center Profile p-2 text-base font-normal text-white"
                    id="drop-button"
                    onClick={handleExpandButton}
                  >
                    <svg
                      width="23"
                      height="25"
                      viewBox="0 0 23 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="profile"
                    >
                      <path
                        d="M21.0413 22.0447L20.3342 21.3376L21.0413 22.0447C21.6547 21.4312 21.9994 20.5993 21.9994 19.7318C21.9994 18.262 21.4155 16.8524 20.3762 15.8131C19.3369 14.7738 17.9273 14.1899 16.4575 14.1899H7.37386C5.90408 14.1899 4.49449 14.7738 3.4552 15.8131C2.4159 16.8524 1.83203 18.262 1.83203 19.7318C1.83203 20.5993 2.17664 21.4312 2.79006 22.0447C3.40348 22.6581 4.23545 23.0027 5.10295 23.0027H18.7284C19.5959 23.0027 20.4279 22.6581 21.0413 22.0447Z"
                        stroke="#68859B"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9161 10.6467C14.3497 10.6467 16.3225 8.67393 16.3225 6.24036C16.3225 3.80679 14.3497 1.83398 11.9161 1.83398C9.48257 1.83398 7.50977 3.80679 7.50977 6.24036C7.50977 8.67393 9.48257 10.6467 11.9161 10.6467Z"
                        stroke="#68859B"
                        stroke-width="2"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Companies
                    </span>
                  </button>
                  <div id="dropdown-container" className={`dash-nav-link ${isDropdownVisible && 'show-dropdown'}`}>
                    <Link to="/superAdmin/CompanyApproveRequest">
                      Approve Request
                    </Link>
                    <Link to="/superAdmin/CompanyList">Company List</Link>
                  </div>
                </li>


                <li className="dash-nav-link">
                  <Link to="/superAdmin/ReviewsList" className="">
                    <svg
                      width="23"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="database"
                    >
                      <path
                        d="M2.22222 10.5C2.22222 10.8459 2.73444 11.4483 3.92222 12.0396C5.46 12.8045 7.64111 13.2632 10 13.2632C12.3589 13.2632 14.54 12.8045 16.0778 12.0396C17.2656 11.4483 17.7778 10.8459 17.7778 10.5V8.10047C15.9444 9.22784 13.1411 9.94737 10 9.94737C6.85889 9.94737 4.05556 9.22674 2.22222 8.10047V10.5ZM17.7778 13.6268C15.9444 14.7542 13.1411 15.4737 10 15.4737C6.85889 15.4737 4.05556 14.7531 2.22222 13.6268V16.0263C2.22222 16.3723 2.73444 16.9746 3.92222 17.5659C5.46 18.3308 7.64111 18.7895 10 18.7895C12.3589 18.7895 14.54 18.3308 16.0778 17.5659C17.2656 16.9746 17.7778 16.3723 17.7778 16.0263V13.6268ZM0 16.0263V4.97368C0 2.22711 4.47778 0 10 0C15.5222 0 20 2.22711 20 4.97368V16.0263C20 18.7729 15.5222 21 10 21C4.47778 21 0 18.7729 0 16.0263ZM10 7.73684C12.3589 7.73684 14.54 7.27816 16.0778 6.51332C17.2656 5.922 17.7778 5.31963 17.7778 4.97368C17.7778 4.62774 17.2656 4.02537 16.0778 3.43405C14.54 2.66921 12.3589 2.21053 10 2.21053C7.64111 2.21053 5.46 2.66921 3.92222 3.43405C2.73444 4.02537 2.22222 4.62774 2.22222 4.97368C2.22222 5.31963 2.73444 5.922 3.92222 6.51332C5.46 7.27816 7.64111 7.73684 10 7.73684Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 whitespace-nowrap">Reviews</span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link to="/superAdmin/Billing" className="Billing">
                    <svg
                      width="23"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      id="billing"
                    >
                      <path
                        d="M17 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V13H20V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20ZM16 15V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V15H16ZM14 18V2H2V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H14ZM4 5H12V7H4V5ZM4 9H12V11H4V9ZM4 13H9V15H4V13Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Billing
                    </span>
                  </Link>
                </li>

                <li className="dash-nav-link">
                  <Link to="/superAdmin/DeleteRequest" className="Deletion">
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.2875 19.5557V19.5556V3.66667V3.56667H1.1875H0.1V1.32222H5.9375H6.0375V1.22222V0.1H12.9625V1.22222V1.32222H13.0625H18.9V3.56667H17.8125H17.7125V3.66667V19.5556C17.7125 20.2019 17.4898 20.7526 17.0425 21.2129C16.5954 21.6731 16.0622 21.9008 15.4376 21.9H15.4375H3.5625C2.93708 21.9 2.40344 21.6719 1.95628 21.2116C1.50905 20.7513 1.28674 20.2012 1.2875 19.5557ZM15.5375 3.66667V3.56667H15.4375H3.5625H3.4625V3.66667V19.5556V19.6556H3.5625H15.4375H15.5375V19.5556V3.66667ZM8.2125 6.21111V17.0111H6.0375V6.21111H8.2125ZM12.9625 6.21111V17.0111H10.7875V6.21111H12.9625Z"
                        fill="#68859B"
                        stroke="#0D2436"
                        stroke-width="0.2"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Deletion Request
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link to="/superAdmin/Reports" className="Reports">
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 19 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.4286 2.46429H13.9643V1.64286C13.9643 1.20714 13.7912 0.789277 13.4831 0.481182C13.175 0.173086 12.7571 0 12.3214 0H5.75C5.31429 0 4.89642 0.173086 4.58832 0.481182C4.28023 0.789277 4.10714 1.20714 4.10714 1.64286V2.46429H1.64286C1.20714 2.46429 0.789277 2.63737 0.481182 2.94547C0.173086 3.25356 0 3.67143 0 4.10714V21.3571C0 21.7929 0.173086 22.2107 0.481182 22.5188C0.789277 22.8269 1.20714 23 1.64286 23H16.4286C16.8643 23 17.2822 22.8269 17.5902 22.5188C17.8983 22.2107 18.0714 21.7929 18.0714 21.3571V4.10714C18.0714 3.67143 17.8983 3.25356 17.5902 2.94547C17.2822 2.63737 16.8643 2.46429 16.4286 2.46429ZM5.75 1.64286H12.3214V4.92857H5.75V1.64286ZM16.4286 21.3571H1.64286V4.10714H4.10714V6.57143H13.9643V4.10714H16.4286V21.3571Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Reports
                    </span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link to="/superAdmin/Issues" className="Issue">
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 15 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 0H15V20H0V0ZM13.75 18.75V1.25H1.25V18.75H13.75ZM7.5 5V6.25H2.5V5H7.5ZM7.5 10V11.25H2.5V10H7.5ZM7.5 15V16.25H2.5V15H7.5ZM12.3145 4.18945L10 6.50391L8.31055 4.81445L9.18945 3.93555L10 4.74609L11.4355 3.31055L12.3145 4.18945ZM12.3145 9.18945L10 11.5039L8.31055 9.81445L9.18945 8.93555L10 9.74609L11.4355 8.31055L12.3145 9.18945ZM12.3145 14.1895L10 16.5039L8.31055 14.8145L9.18945 13.9355L10 14.7461L11.4355 13.3105L12.3145 14.1895Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 whitespace-nowrap">Issue List</span>
                  </Link>
                </li>
                <li className="dash-nav-link">
                  <Link
                    to="/superAdmin/ReportedReviewSave"
                    className="Reported"
                  >
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 14H4V7H6V14ZM10 14H8V4H10V14ZM14 14H12V10H14V14ZM16 16H2V2H16V16.1M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
                        fill="#68859B"
                      />
                    </svg>
                    <span className="flex-1 text-left whitespace-nowrap">
                      Reported Reviews
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default SideNav;
