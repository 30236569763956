import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import Dashboardnavbar from "../../../Widgets/Dashboardnavbar.js";
import CommonNavbar from "../../../Widgets/CommonNavbar.js";
import NonJoiners from "./nonJoiners.js";
import AddExEmployeeForm from "./addExEmployeeForm.js";
import AddNonJoinerForm from "./addNonJoinersForm.js";
import { getUserCompany, ProfileData } from "../../../Services/authHeader.js";
import ExEmployees from "./exEmployess.js";
import { PageConfig } from "../../../Services/Context.js";
import Model from "../../../Services/model.js";
import { CompanyWorkFlowConfig } from "../../../Services/companyContext.js";


const { Content, Sider } = Layout;

function Reviews() {
  const [getUser, setGetUser] = useState(null);
  const [tabType, setTabType] = useState("ExEmployee");
  const [formType, setFormType] = useState(null);
  const [userCompany, setUserCompany] = useState("");
  const [loading, setLoading] = useState(true);
  const { TypeTab } = PageConfig()
  const {workflow_state}=CompanyWorkFlowConfig()




  useEffect(() => {
    // Retrieve user data from session storage
    const storedUser = sessionStorage.getItem("usr");
    if (storedUser) {
      setGetUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (getUser) {
      fetchCompanyDetails();
    }
  }, [getUser]);

  const fetchCompanyDetails = async () => {
    try {
      const email = getUser.email;
      const res = await getUserCompany(email);
      if (res.status === 200) {
        // console.log("Company:", res.data.data.role_profile_name);
        setUserCompany(res.data.data.company);
      } else {
        console.log("Error fetching company name!");
      }
    } catch (error) {
      console.log("Error fetching company details:", error);
    } finally {
      setLoading(false); // Finish loading
    }
  };

  const handleResetButton = (value) => {
    if (value === true) {
      setTabType("ExEmployee");
      setFormType(null);
    }
  };

  const editableForm = () => {
    if (tabType === "ExEmployee" || tabType === "NonJoiner") {
      setFormType(tabType);
    }
  };

  const closeForm = () => {
    setFormType(null);
    if (TypeTab === "ExEmployee") {
      setTabType("ExEmployee");
    } else {
      setTabType("NonJoiner");
    }
  };

  document.body.classList.add("review-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page"
  );

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (loading) {
    return <Model spinLoading={loading} />;
  }



  // const workflow_state = JSON.parse(sessionStorage.getItem("workflow_state"))

  return (
    <Layout className="h-screen">
      <div id="dark-mode-on"></div>
      <Sider width={256} className="site-layout-background h-screen">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar />
        <Content>
          <div className="site-layout-background">
            <Row
              justify="space-between"
              align="middle"
              className="tabs-addReviewBtn"
              key={tabType}
            >
              <Col className="toggle-btns">
                <Button
                  type="primary"
                  className={
                    tabType === "ExEmployee" ? "dark-color" : "light-color"
                  }
                  onClick={() => {
                    setTabType("ExEmployee");
                  }}
                >
                  Ex-Employee
                </Button>
                <Button
                  type="primary"
                  className={
                    tabType === "NonJoiner" ? "dark-color" : "light-color"
                  }
                  onClick={() => {
                    setTabType("NonJoiner");
                  }}
                >
                  Non-Joiners
                </Button>
              </Col>
              <Col>
                <Button type="default" onClick={editableForm} disabled={!workflow_state}>
                  Add Review
                </Button>
              </Col>
            </Row>
            {formType === "ExEmployee" && (
              <AddExEmployeeForm onClose={closeForm} />
            )}
            {formType === "NonJoiner" && (
              <AddNonJoinerForm onClose={closeForm} />
            )}
            {formType === null &&
              (tabType === "ExEmployee" ? (
                <ExEmployees userCompany={userCompany} />
              ) : (
                <NonJoiners userCompany={userCompany} />
              ))}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Reviews;
