import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";
import {
  deleteRequestList,
  getExEmployeeDetails,
} from "../../../Services/authHeader";
import ExEmployee from "../../AdminDashboard/Reviews/addExEmployeeForm";

const ExEmployees = ({ referenceDoctype, isDeleteMode }) => {
  const [employees, setEmployees] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  console.log(referenceDoctype);
  const getDeletionListing = async () => {
    try {
      const doctype = "Deletion Request";
      const res = await deleteRequestList(doctype);

      if (res?.data?.message) {
        const fetchedData = res.data.message;

        const filteredData = fetchedData.filter(
          (item) =>
            item.reference_doctype === referenceDoctype &&
            item.request_type === "Delete"
        );
    
        setEmployees(filteredData);
      } else {
        console.log("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching deletion listing:", error);
    }
  };
  const isSuperAdmin = true;

  useEffect(() => {
    getDeletionListing();
  }, [referenceDoctype]);

  const handleSelectRow = (e, key) => {
    if (e.target.checked) {
      setSelectedRows((prev) => [...prev, key]);
    } else {
      setSelectedRows((prev) => prev.filter((row) => row !== key));
    }
  };

  const columns = [
    ...(isDeleteMode
      ? [
          {
            title: "Select",
            key: "select",
            render: (_, record) => (
              <Checkbox
                onChange={(e) => handleSelectRow(e, record.reference_name)}
                checked={selectedRows.includes(record.reference_name)}
              />
            ),
          },
        ]
      : []),
    { title: "ID", dataIndex: "reference_name", key: "reference_name" },
    {
      title: "Submitted By",
      dataIndex: "request_creator",
      key: "request_creator",
    },
    { title: "Amended From", dataIndex: "amended_from", key: "amended_from" },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text) => (
        <span title={text}>
          {text?.length > 50 ? `${text.substring(0, 50)}...` : text}
        </span>
      ),
    },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "view",
      render: (_, record) => (
        <button
          onClick={() => handleViewClick(record)}
          className="text-blue-600 hover:underline"
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = async (record) => {
    try {
      const res = await getExEmployeeDetails(record.reference_name);
      if (res?.data) {
        setSelectedEmployee(res.data.data); // Assuming the response data contains the details you need
        setShowForm(true);
      } else {
        console.log("No details found for this record");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedEmployee(null);
  };
  const handleReset = () => {
    setSelectedEmployee(null);
  };

  // const handleFormSubmit = async () => {
  //   await getEmployees();
  //   handleCloseForm();
  // };

  return (
    <div>
      {showForm ? (
        <ExEmployee
          employee={selectedEmployee}
          onClose={handleCloseForm}
          reset={handleReset}
          // onFormSubmit={handleFormSubmit}
          isSuperadmin={isSuperAdmin}
        />
      ) : (
        <Table
          dataSource={employees}
          columns={columns}
          rowKey="reference_name"
          pagination={{ pageSize: 10 }}
        />
      )}
    </div>
  );
};

export default ExEmployees;
