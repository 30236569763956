import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import Dashboardnavbar from "../../../Widgets/Dashboardnavbar.js";
import CommonNavbar from "../../../Widgets/CommonNavbar.js";
import NonJoiners from "./nonJoiners.js";
import AddExEmployeeForm from "./addExEmployeeForm.js";
import AddNonJoinerForm from "./addNonJoinersForm.js";
import AuthHeader from "../../../Services/authHeader.js";
import ExEmployees from "./exEmployess.js";
import SuperAdminNav from "../../../Widgets/SuperAdminNavbar.js";
import SuperAdminSideNavbar from "../../../Widgets/SuperAdminDashboardNavbar.js";
import { PageConfig } from "../../../Services/Context.js";

const { Content, Sider } = Layout;

function Reviews() {
  const [tabType, setTabType] = useState("ExEmployee");
  const [formType, setFormType] = useState(null);

  const { setDropdownVisible} = PageConfig();

  useEffect(()=>{
    setDropdownVisible(false)
  },[])

  const handleResetButton = (value) => {
    if (value === true) {
      setTabType("ExEmployee");
      setFormType(null);
    }
  };

  const editableForm = () => {
    if (tabType === "ExEmployee" || tabType === "NonJoiner") {
      setFormType(tabType);
    }
  };

  const closeForm = () => {
    setFormType(null);
    setTabType("ExEmployee");
  };

  document.body.classList.add("review-page");
  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page",
    "company-review-page",
    "approve-page",
    "deletion-page",
    "companyreportsave-page",
    "issue-page",
    "reports-page"
  );

  return (
    <Layout className="h-screen">
      <div id="dark-mode-on"></div>
      <Sider width={256} className="site-layout-background h-screen">
        <SuperAdminSideNavbar />
      </Sider>
      <Layout className="panel-right">
        <SuperAdminNav/>
        <Content>
          <div className="site-layout-background">
            <Row
              justify="space-between"
              align="middle"
              className="tabs-addReviewBtn"
            >
              <Col className="toggle-btns">
                <Button
                  type="primary"
                  className={
                    tabType === "ExEmployee" ? "dark-color" : "light-color"
                  }
                  onClick={() => {
                    setTabType("ExEmployee");
                  }}
                >
                  Ex-Employee
                </Button>
                <Button
                  type="primary"
                  className={
                    tabType === "NonJoiner" ? "dark-color" : "light-color"
                  }
                  onClick={() => {
                    setTabType("NonJoiner");
                  }}
                >
                  Non-Joiners
                </Button>
              </Col>
              <Col>
                <Button type="default" onClick={editableForm}>
                  Add Review
                </Button>
              </Col>
            </Row>
            {formType === "ExEmployee" && (
              <AddExEmployeeForm onClose={closeForm} />
            )}
            {formType === "NonJoiner" && (
              <AddNonJoinerForm onClose={closeForm} />
            )}
            {formType === null &&
              (tabType === "ExEmployee" ? <ExEmployees /> : <NonJoiners />)}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Reviews;
