import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProcessOrder = (amount, phoneno, subscription_plan_type, points) => {

  //  const navigate=useNavigate()
  axios
    .post("/api/method/phonepe_integration.api.process_order", {
      params: {
        amount: amount * 100,
        redirectUrl: window.location.origin + "/Billing",
        callbackUrl: window.location.origin + "/Billing",
        mobileNumber: phoneno,
        subscription_plan_type: subscription_plan_type,
        points: points,
      },
    })
    .then((data) => {
      // console.log(data.data.message, "databhaxbhab");
      if (data.data.message.success === true) {
        // Use window.location.href to open in the same tab
        // navigate('/Billing')
        window.location.href =
          data.data.message.data.instrumentResponse.redirectInfo.url;
      } else {
        alert(data.data.message);
      }
    })
    .catch((error) => {
      console.error("Error processing order:", error);
      alert("An error occurred while processing your order.");
    });
};

export default ProcessOrder;
