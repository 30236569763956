import React, { useEffect, useState } from "react";
import SuperAdminNav from "../../Widgets/SuperAdminNavbar";
import SideNav from "../../Widgets/SideNav";
// import User from "../../Assets/Images/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Graph from "./graph";
import {
  getAllUserCompany,
  getExEmployee,
  getNonJoinerEmployee,
  getrecentlyAddedReviewsData,
} from "../../Services/authHeader";
import { useNavigate } from "react-router-dom";

import SuperAdmingraph2 from "../../Assets/Images/SuperAdmingraph2.png";

// import Prizes from '../../Assets/Images/prize.png'
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import { Layout, Table, Tabs } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import TabPane from "antd/es/tabs/TabPane";
import {
  fetchSubscriptionTransactionList,
  getApprovedCompanies,
} from "../../Services/superadminService";
import SubscriptionChart from "./SubscriptionChart";
import { PageConfig } from "../../Services/Context";

const Home = () => {
  const getUserName = sessionStorage.getItem("name");
  const navigate = useNavigate();
  const [showbutton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [recentlyAddedCompnies, setRecentlyAddedCompnies] = React.useState([]);
  const [recentlySearchedReviews, setRecentlySearchedReviews] = React.useState(
    []
  );
  const [nonJoiners, setNonJoiners] = useState([]);
  const [exEmployees, setExEmployees] = useState([]);
  const [activeTab, setActiveTab] = useState("exEmployees");
  const [approvedCompanies, setApprovedCompanies] = useState([]);

  const { setDropdownVisible} = PageConfig();
  useEffect(()=>{
    setDropdownVisible(false)
  },[])
  

  let getLoginUserName = sessionStorage.getItem("usr");
  if (getLoginUserName) {
    try {
      const parsedUser = JSON.parse(getLoginUserName);
      getLoginUserName = parsedUser.username;
    } catch (error) {
      console.error("Error parsing user data:", error);
      getLoginUserName = null; // or some default value
    }
  }
  async function fetchNonJoiners() {
    try {
      const data = await getNonJoinerEmployee();
      // console.log("Non Joiner Data:", data);
      setNonJoiners(data);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  async function fetchExEmployees() {
    try {
      const data = await getExEmployee();
      // console.log("Ex Employees Data:", data);
      setExEmployees(data);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  useEffect(() => {
    // Initially fetch the Ex-Employees data
    fetchExEmployees();
  }, []);
  useEffect(() => {
    gettabledata();
  }, []);
  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  async function fetchCompanies() {
    try {
      const approvedData = await getApprovedCompanies();
      // Format the creation date for each approved company
      const formattedApprovedData = approvedData.map((company) => ({
        ...company,
        creation: formatDateToDDMMYYYY(company.creation), // Format date
      }));
      setApprovedCompanies(formattedApprovedData);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, []);

  function gettabledata() {
    getAllUserCompany()
      .then((alldata) => {
        let data = alldata.data.result;
        // console.log("Data received from searchUsers:", data);
        data.forEach((user) => {
          user.date = formatDateToDDMMYYYY(user.creation);
        });
        setRecentlyAddedCompnies(data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    getrecentlyAddedReviewsData("User")
      .then((data) => {
        // console.log("Data received from searchUsers:", data);
        data.forEach((user) => {
          user.date = formatDateToDDMMYYYY(user.creation);
        });
        setRecentlySearchedReviews(data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }
  const columns = [
    { title: "User Name", dataIndex: "first_name", key: "first_name" },
    { title: "Date", dataIndex: "date", key: "date",
      className: "table-date" },
  ];

  const approvedColumns = [
    { title: "Company Name", dataIndex: "name", key: "name" },
    { title: "Creation Date", dataIndex: "creation", key: "creation",
      className: "table-date" },
  ];

  const filteredData = activeTab === "nonJoiners" ? nonJoiners : exEmployees;

  filteredData.forEach((user) => {
    user.date = formatDateToDDMMYYYY(user.creation); // Assuming `creation` is the date field
  });

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === "nonJoiners") {
      fetchNonJoiners();
    } else if (key === "exEmployees") {
      fetchExEmployees();
    }
  };

  const [recentSubscriptions, setRecentSubscriptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const data = await fetchSubscriptionTransactionList();
        const formattedSubscriptions = data.map((subscription) => ({
          ...subscription,
          creation: formatDateToDDMMYYYY(subscription.creation), // Format date
        }));
        setRecentSubscriptions(formattedSubscriptions);
      } catch (error) {
        console.error("Error fetching subscription transaction data:", error);
      }
    };

    fetchSubscriptions();
  }, []);

  const subscriptionColumns = [
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "ID", dataIndex: "name", key: "name" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Date", dataIndex: "creation", key: "creation" },
  ];

  document.body.classList.add("dashboard-page");
  document.body.classList.remove("profile-page");
  document.body.classList.remove("review-page");
  document.body.classList.remove("billing-page");
  document.body.classList.remove("search-page");
  document.body.classList.remove("deletion-page");
  document.body.classList.remove("companyreportsave-page");
  document.body.classList.remove("issue-page");
  document.body.classList.remove("approve-page");


  return (
    <>
      <div id="dark-mode-on"></div>
      <Layout className="h-full">
        {/* <ToastContainer /> */}
        <Sider width={256} className="site-layout-background">
          <SideNav />
        </Sider>
        <Layout className="panel-right">
          <SuperAdminNav />
          <Content className="dashboard-layout">
            {/* Modal for button */}
            <div class="flex flex-wrap p-5 pt-12 flex-col justify-between md:flex-row">
              <div className="heading-home">
                <h2 className="text-xl dashboard-home-text">
                  Welcome, {getUserName || getLoginUserName}
                </h2>
              </div>
            </div>
            {/* Card for table */}
            <div class=" w-auto ">
              {/* Recently Add Reviews  */}
              <div className="dashboard-row">
                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Recently Added companies</h2>
                  </div>
                  <Table
                    dataSource={approvedCompanies.slice(0, 7)}
                    columns={approvedColumns}
                    pagination={false}
                  />
                  <Link to="/SuperAdmin/CompanyList" className="view-all">
                    View All
                  </Link>
                </div>

                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Recently Added Reviews</h2>
                    <div className="toggle-buttons">
                      <Tabs
                        defaultActiveKey="exEmployees"
                        onChange={handleTabChange}
                      >
                        <TabPane tab="Ex-Employees" key="exEmployees" />
                        <TabPane tab="Non-Joiners" key="nonJoiners" />
                      </Tabs>
                    </div>
                  </div>
                  <Table
                    dataSource={filteredData.slice(0, 7)}
                    columns={columns}
                    pagination={false}
                  />
                  <Link to="/superAdmin/ReviewsList" className="view-all">
                    View All
                  </Link>
                </div>
              </div>

              <div className="dashboard-row">
                <div className="dashboard-blocks">
                  <div className="dashboard-header">
                    <h2>Recently Subscriptions</h2>
                  </div>
                  <Table
                    dataSource={recentSubscriptions.slice(0, 7)}
                    columns={subscriptionColumns}
                    pagination={false}
                  />
                  <Link to="/SuperAdmin/reports" className="view-all">
                    View All
                  </Link>
                </div>
              </div>

              <div className="dashboard-row">
                <SubscriptionChart />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default Home;
