import React, { useEffect, useState } from "react";
import Dashboardnavbar from "../../Widgets/Dashboardnavbar";
import CommonNavbar from "../../Widgets/CommonNavbar";
import {
  userSubscriptionData,
  getSubscriptionList,
  getSubscriptionHistoryByCompany,
  getUserCompany,
} from "../../Services/authHeader";
import { toast, ToastContainer } from "react-toastify";
import { Layout, Table } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import Card from "./card";

const Billing = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [subscriptionTableData, setSubscriptionTableData] = useState([]);


  useEffect(() => {
    const id = JSON.parse(sessionStorage.getItem("usr"));
    const userID = id.email;
    subscriptionDataGet();
    fetchSubscriptionTableData(userID);
  }, []);

  const subscriptionDataGet = async () => {
    try {
      const res = await userSubscriptionData();
      if (res.status === 200) {
        // console.log(res.data.message.subscription_table);
        setSubscriptionData(res.data.message.subscription_table);
      } else {
        toast.error("Error Getting Subscription Data");
        console.log("Error Getting Subscription Data");
      }
    } catch (error) {
      console.log("Error Getting Subscription Data", error);
    }
  };

  useEffect(() => {
    // Retrieve user data from session storage
    const storedUser = sessionStorage.getItem("usr");
    if (storedUser) {
      let temp = JSON.parse(storedUser);
      fetchSubscriptionTableData(temp);
    }
  }, []);

  const fetchSubscriptionTableData = async (userData) => {
    try {
      const email = userData.email;
      const res2 = await getUserCompany(email);
      const res = await getSubscriptionHistoryByCompany(res2.data.data.company);
      if (res && res.result) {
        const CompletedSubscriptionData=res.result.filter((res)=>{
          if(res.status === "Completed"){
            return res
          }
        })
        setSubscriptionTableData(CompletedSubscriptionData);
      } else {
        toast.error("Error Getting Subscription List Data");
        console.log("Error Getting Subscription List Data");
      }
    } catch (error) {
      console.log("Error Getting Subscription List Data", error);
    }
  };


  document.body.classList.remove(
    "dashboard-page",
    "profile-page",
    "billing-page",
    "search-page",
    "users-page"
  );
  document.body.classList.add("billing-page");
  document.body.classList.remove("review-page")

  // Ant Design Table Columns Configuration
  const columns = [
    {
      title: "Sr. NO",
      dataIndex: "idx",
      key: "idx",
      render: (text, record, index) => index + 1, // Sr. No. will auto-increment
    },
    {
      title: "SUBSCRIPTION ID",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "SUBSCRIPTION DATE",
      dataIndex: "creation",
      key: "creation",
      render: (text) => new Date(text).toLocaleDateString(), // Format date if needed
    },
    {
      title: "PLAN NAME",
      dataIndex: "data", // data contains a JSON string, need to parse it
      key: "planName",
      render: (text) => {
        const parsedData = JSON.parse(text); // Parse the data field
        return parsedData.subscription_plan_type || "N/A";
      },
    },
    {
      title: "AMOUNT PAID",
      dataIndex: "data", // Amount is also in data
      key: "amountPaid",
      render: (text) => {
        const parsedData = JSON.parse(text);
        return parsedData.amount ? `₹ ${parsedData.amount}` : "N/A";
      },
    },
    {
      title: "POINTS PROVIDED",
      dataIndex: "data", // Assuming points is in the data field
      key: "pointsProvided",
      render: (text) => {
        const parsedData = JSON.parse(text);
        return parsedData.points || "N/A";
      },
    },
  ];

  return (
    <Layout className="h-screen">
      <ToastContainer />
      <Sider width={256} className="site-layout-background">
        <Dashboardnavbar />
      </Sider>
      <Layout className="panel-right">
        <CommonNavbar />
        <Content>
          <div className="billing-page">
            <div className="w-auto overflow-auto billing-section">
              {subscriptionData.length > 0 ? (
                subscriptionData
                  .slice(0, 4)
                  .map((data, index) => <Card key={index} data={data} fetchSubscriptionTableData={fetchSubscriptionTableData} subscriptionTableData={subscriptionTableData}/>)
              ) : (
                <p>No subscription data available.</p>
              )}
            </div>

            <div
              className="billing-section-second"
              style={{ paddingTop: "2.5rem", maxHeight: "30rem" }}
            >
              <div className="billing-section-second-right">
                <Table
                  dataSource={subscriptionTableData}
                  columns={columns}
                  rowKey="name"
                  pagination={{ pageSize: 10 }}
                  title={() => <h2>Subscription History</h2>}
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Billing;
